import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from '@iconify/react';
import { Button, Card, IconButton, Radio, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TEETH } from '../../../assets/data/teeth';
import EMPTY from '../../../assets/illustrations/illustration_empty_content.svg';
import Iconify from '../../../components/iconify';
import LoadingScreen from '../../../components/loading-screen';
import { getSearchedOptions } from '../../../redux/slices/option/optionThunk';
import { getOnePatient } from '../../../redux/slices/patient/patientThunk';
import { deleteDent, insertFiles, insertNum, insertPatient, insertPhy, resetStep, setDents, setDescription, setTreatment, setUpdate, } from '../../../redux/slices/prothese/protheseSlice';
import { getAllTypes } from '../../../redux/slices/type/typeThunk';
import { dispatch, useSelector } from '../../../redux/store';
import { formatDate } from '../../../utils/formatDate';
import EmptyProthese from './prothese/EmptyProthese';
import LeftOptionSections from './prothese/LeftOptionSections';
import ProtheseUserInfo from './prothese/ProtheseUserInfo';
import RightOptionsSection from './prothese/RightOptionsSection';
import TeethSection from './prothese/TeethSection';
import TreatmentDescription from './prothese/TreatmentDescription';
import UploadMultiple from './prothese/UploadMultiple';
import { attachementStyle, emptySection, treatmentSection } from './styles';
import { getProtheseById } from '../../../redux/slices/prothese/protheseThunk';
import { getFileExtension } from '../../../utils/getFileExtension';
import { StlViewer } from 'react-stl-viewer';
import { formatTimestamp } from '../../../utils/formatTimestamp';
import Tooth from '../../../assets/icons/Tooth';
import ViewFile from '../../../components/view-file';
export default function PatientSecondStep({ selectedDent, setSelectedDent, num, setNum, phy, setPhy, }) {
    const [selectedType, setSelectedType] = useState('');
    const [selectedArticle, setSelectedArticle] = useState('');
    const [types, setTypes] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(0);
    const { oneData: prothese } = useSelector((state) => state.dent);
    const [viewSTL, setViewStl] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');
    const [isStl, setIsStl] = useState(false);
    const { id, treatmentId } = useParams();
    const _id = useSelector((state) => state?.patient?.onData?._id);
    const [icons, setIcons] = useState([]);
    useEffect(() => {
        treatmentId && dispatch(getProtheseById({ id: treatmentId }));
    }, [treatmentId]);
    useEffect(() => {
        if (id) {
            dispatch(getOnePatient({ id }));
        }
        dispatch(getAllTypes({ limit: 1000 }));
        dispatch(getSearchedOptions({ searchType: 'COLOR' }));
        if (_id)
            dispatch(insertPatient(_id));
        const loadIcons = async () => {
            const loadedIcons = await Promise.all(TEETH.map(async (tooth) => {
                return {
                    id: tooth.id,
                    icon: await tooth.icon,
                };
            }));
            setIcons(loadedIcons);
        };
        loadIcons();
    }, [id]);
    useEffect(() => {
        if (treatmentId && prothese) {
            const value = prothese?.traitement?.dents?.map((el) => {
                return {
                    color: el?.color,
                    name: el?.name,
                    ombre: el?.ombre,
                    remarks: el?.remarks,
                    price: el?.price,
                    type: el?.type?._id,
                    material: el?.material?._id,
                    confirmed: true,
                };
            });
            if (value)
                setSelectedDent(Number(value[0]?.name) || 1);
            if (value)
                dispatch(setDents(value));
            dispatch(insertFiles(prothese?.attachments));
            dispatch(resetStep());
            dispatch(setTreatment(prothese?.traitement?._id || ''));
            dispatch(setUpdate());
            dispatch(setDescription(prothese?.traitement?.description || ''));
        }
    }, [treatmentId, prothese]);
    const patientData = useSelector((state) => state.patient);
    const test = useSelector((state) => state.dent);
    const digitalFootprint = !!test?.oneData?.digitalFootprint;
    const physicalFootprint = !!test?.oneData?.physicalFootprint;
    const files = test.files;
    const options = useSelector((state) => state.option.allOptions);
    const data = patientData.onData;
    const error = patientData.error;
    const done = test.done;
    const teeth = test.dents.filter((teeth) => teeth.confirmed === true);
    const filter = ['ESPACE_CIMENT', 'EPAISSEUR_MINIMALE'];
    const treatment = useSelector((state) => state.treatment);
    const { allTypes } = useSelector((state) => state.type);
    const getSelectedTypeById = (id) => {
        if (!id || allTypes.length === 0) {
            return '';
        }
        const selectedType = allTypes.find((type) => type._id === id);
        return selectedType ? selectedType.code : '';
    };
    const [table, setTable] = useState([
        ["Type d'analyse :", 'Nom du fichier', 'Date'],
    ]);
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        dispatch(insertFiles(filtered));
    };
    useEffect(() => {
        const table = [["Type d'analyse :", 'Nom du fichier', 'Date']];
        files?.map((el) => {
            const row = [el?.path.split('.')[0], el?.path, formatDate(el?.lastModifiedDate)];
            table.push(row);
        });
        setTable(table);
    }, [files]);
    useEffect(() => {
        if (physicalFootprint) {
            setPhy(physicalFootprint);
            dispatch(insertPhy(physicalFootprint));
        }
        if (digitalFootprint) {
            setNum(digitalFootprint);
            dispatch(insertNum(digitalFootprint));
        }
    }, [physicalFootprint, digitalFootprint]);
    return (_jsxs(Box, { children: [_jsx(Box, { display: 'flex', sx: { gap: '15px', width: '100%' }, children: error ? (_jsxs(Box, { sx: emptySection, children: [_jsx("img", { src: EMPTY, alt: "error", style: { margin: '0 auto' } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Patient introuvable ou quelque chose c'est mal pass\u00E9" })] })) : data && options ? (_jsxs(Stack, { width: '100%', children: [_jsx(Box, { display: 'flex', sx: { gap: '15px' }, width: '100%', children: _jsx(ProtheseUserInfo, { data: data }) }), _jsx(Stack, { width: '100%', children: _jsxs(Box, { display: 'flex', sx: { gap: '15px', position: 'relative' }, width: '100%', children: [_jsx(TeethSection, { selectedDent: selectedDent, setSelectedDent: setSelectedDent, setSelectedArticle: setSelectedArticle, setSelectedType: setSelectedType, setTypes: setTypes, setCurrentPrice: setCurrentPrice }), _jsx(Stack, { width: '60%', children: selectedDent ? (_jsx(_Fragment, { children: _jsx(Stack, { children: _jsxs(Card, { sx: {
                                                        marginTop: '15px',
                                                        width: '100%',
                                                        padding: '22px',
                                                    }, children: [_jsx(Typography, { color: '#6D758F', sx: { fontSize: '18px', fontWeight: 600 }, children: "2- Types de reconstruction\u00A0:" }), _jsx(LeftOptionSections, { options: options, filter: filter, selectedDent: selectedDent, types: types, setSelectedType: setSelectedType, selectedType: selectedType, setCurrentPrice: setCurrentPrice }), teeth.length > 0 && (_jsx(Card, { sx: { padding: '15px', marginTop: '15px' }, children: _jsx(Box, { sx: treatmentSection, children: teeth?.map((el, index) => {
                                                                    const selectedTooth = icons.find((tooth) => +tooth.id === +el.name);
                                                                    return (_jsxs(Box, { sx: {
                                                                            display: 'flex',
                                                                            gap: '10px',
                                                                            justifyContent: 'start',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            borderRadius: '10px',
                                                                        }, children: [_jsxs(Box, { sx: {
                                                                                    display: 'flex',
                                                                                    gap: '0px',
                                                                                    justifyContent: 'center',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center',
                                                                                    borderRadius: '10px',
                                                                                    cursor: 'pointer',
                                                                                    width: '100px !important',
                                                                                }, children: [_jsx(Box, { sx: {
                                                                                            padding: '10px 5px',
                                                                                            borderRadius: '20px',
                                                                                            width: '100px !important',
                                                                                        }, children: icons.length > 0 && (_jsx("img", { src: selectedTooth?.icon, alt: "error", style: { margin: '0 auto' } })) }), _jsx(Typography, { variant: "h6", color: 'text.secondary', children: selectedTooth?.id })] }), _jsxs(Box, { sx: {
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    gap: '30px',
                                                                                    flex: 1,
                                                                                }, children: [el?.ombre && (_jsxs(Stack, { alignItems: "center", children: [_jsx(Tooth, { color: el?.color }), _jsx(Typography, { variant: "caption", children: el?.ombre })] })), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: getSelectedTypeById(el?.type) }), _jsx(Box, { sx: {
                                                                                            flex: 1,
                                                                                            display: 'flex',
                                                                                            justifyContent: 'flex-end',
                                                                                        }, children: _jsx(IconButton, { onClick: () => {
                                                                                                dispatch(deleteDent(el.name));
                                                                                                setSelectedType('');
                                                                                                setSelectedDent(-1);
                                                                                            }, color: "error", children: _jsx(Iconify, { icon: "eva:trash-2-outline" }) }) })] })] }, index));
                                                                }) }) })), _jsx(RightOptionsSection, { options: options, filter: filter, selectedDent: selectedDent, currentPrice: currentPrice })] }) }) })) : (_jsx(EmptyProthese, {})) }), _jsxs(Card, { sx: { mt: '15px', p: '22px', width: '20%', minWidth: '280px' }, children: [_jsx(Typography, { color: "#6D758F", sx: { fontSize: '18px', fontWeight: 600 }, children: "3- Pieces jointes :" }), _jsxs(Box, { sx: {
                                                    marginTop: '15px',
                                                }, children: [_jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            gap: '5px',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            borderRadius: 2,
                                                            cursor: 'pointer',
                                                            padding: '10px',
                                                            background: 'white',
                                                            border: `1px solid ${num ? '#1D98BD' : '#F1F3F7'}`,
                                                        }, width: "100%", onClick: () => {
                                                            setNum(!num);
                                                            dispatch(insertNum(!num));
                                                        }, children: [_jsx(Radio, { checked: num }), _jsx(Typography, { variant: "subtitle1", color: num ? '#006C9C' : '#176C86', children: "Empreinte Num\u00E9rique" })] }), _jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            gap: '5px',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            borderRadius: 2,
                                                            cursor: 'pointer',
                                                            padding: '10px',
                                                            background: 'white',
                                                            border: `1px solid ${phy ? '#1D98BD' : '#F1F3F7'}`,
                                                            my: '15px',
                                                        }, width: "100%", onClick: () => {
                                                            setPhy(!phy);
                                                            dispatch(insertPhy(!phy));
                                                        }, children: [_jsx(Radio, { checked: phy, color: "primary" }), _jsx(Typography, { variant: "subtitle1", color: phy ? '#006C9C' : '#176C86', children: "Empreinte Physique" })] })] }), _jsx(_Fragment, { children: num && (_jsxs(Box, { sx: attachementStyle, children: [_jsx(UploadMultiple, { title: "Joindre des fichiers", open: showUpload, currentFiles: files, onClose: () => {
                                                                setShowUpload(false);
                                                            } }), num &&
                                                            files?.length > 0 &&
                                                            files?.map((el, key) => {
                                                                const extention = getFileExtension(el?.path);
                                                                return (_jsxs(Card, { sx: {
                                                                        width: '100%',
                                                                        borderRadius: 0.75,
                                                                        px: 1,
                                                                        py: 0.75,
                                                                        position: 'relative',
                                                                    }, children: [_jsx(IconButton, { onClick: (e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                setCurrentUrl(el?.preview);
                                                                                setViewStl(true);
                                                                                setIsStl(extention?.toLowerCase() === 'stl' ? true : false);
                                                                            }, sx: { position: 'absolute', left: 7, zIndex: 2 }, size: "small", children: _jsx(Icon, { icon: "mdi:eye-outline" }) }), _jsx(IconButton, { edge: "end", size: "small", onClick: () => handleRemoveFile(el), sx: { position: 'absolute', right: 7, zIndex: 2 }, children: _jsx(Iconify, { icon: "eva:close-fill" }) }), extention?.toLowerCase() === 'stl' ? (_jsx(StlViewer, { orbitControls: true, url: el?.preview ?? el.path, modelProps: { scale: 1.4 } })) : (_jsx(Box, { component: "img", src: el?.preview ?? el.path, sx: {
                                                                                borderRadius: 2,
                                                                                objectFit: 'contain',
                                                                                width: '100%',
                                                                                height: 'auto',
                                                                                maxHeight: '125px',
                                                                                margin: '0 auto',
                                                                                mixBlendMode: 'multiply',
                                                                            } })), _jsxs(Stack, { flexGrow: 1, flexDirection: "row", children: [_jsxs(Stack, { flexGrow: 1, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: el.path }), _jsx(Typography, { variant: "caption", sx: { color: 'text.secondary' }, children: formatTimestamp(el.lastModified, 'fr-FR') })] }), _jsx("a", { href: el?.preview, download: true, children: _jsx(IconButton, { children: _jsx(Icon, { icon: "material-symbols:download", color: "black", width: '20px' }) }) })] })] }, key));
                                                            }), num && !treatment.loading && (_jsxs(Button, { variant: "outlined", color: "primary", onClick: () => {
                                                                setShowUpload(true);
                                                            }, sx: {
                                                                my: '8px',
                                                                width: '100%',
                                                                gap: 1,
                                                                background: '#E5F4FD',
                                                                '&:hover': {
                                                                    background: '#DAF6FF',
                                                                },
                                                            }, children: [_jsx(Iconify, { icon: "akar-icons:cloud-upload" }), "Joindre une pi\u00E8ce jointe"] }))] })) })] })] }) }), _jsx(Box, { children: !done && _jsx(TreatmentDescription, {}) })] })) : (_jsx(LoadingScreen, {})) }), _jsx(ViewFile, { viewSTL: viewSTL, onClose: () => {
                    setViewStl(false);
                    setCurrentUrl('');
                }, currentUrl: currentUrl, isStl: isStl })] }));
}
